@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.alertFade {
  animation: fadeIn 1s ease-in both;
}

.sidenav {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.sidenavOpen {
  height: 100%;
  width: 270px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.mainHeader {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

.closeButton {
  background: #111;
  border: none;
  position: absolute;
  top: 5px;
  left: 10px;
}

.dataContact:hover > .hoverSvg,
.hoverSvg:hover {
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.8));
}

.bg-toast {
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  transition: opacity 0.5s;
  opacity: 1 !important;
  right: 25px !important;
  bottom: 25px !important;
}

.text-toast-trivia {
  font-size: 14px;
  font-weight: 100;
}
